<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学时证明统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel">姓名:</span>
              <el-input v-model="userName" type="text" size="small" placeholder="请输入姓名" clearable />
            </div>
            <div title="身份证号" class="searchboxItem ci-full">
              <span class="itemLabel">身份证号:</span>
              <el-input v-model="idCard" type="text" size="small" placeholder="请输入身份证号" clearable />
            </div>
            <div title="单位名称" class="searchboxItem ci-full">
              <span class="itemLabel">单位名称:</span>
              <el-select
                size="small"
                v-model="unitCompId"
                remote
                :remote-method="getuserCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width:100%"
              >
                <el-option
                  v-for="item in userCompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="100"/>
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" />
              <el-table-column label="单位名称" align="left" show-overflow-tooltip prop="compName"/>
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                 minWidth="150"
              />
              <el-table-column
                label="总学时"
                align="right"
                show-overflow-tooltip
                prop="totalLessonNum"
              />
              <el-table-column
                label="完成学时"
                align="right"
                show-overflow-tooltip
                prop="studyLessonNum"
              />
              <el-table-column label="证书编号" align="left" show-overflow-tooltip prop="certNo" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 15px"
                    @click="lookupTrainCert(scope.row.projectId, scope.row.userId) "
                    :disabled="scope.row.studyProgress==10"
                  >查看学时证明</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="查看学时证明"
      :visible.sync="dialogCert"
      width="50%"
      center
      :before-close="closeDownloadCert"
    >
      <div id="pdf-cert" style="height: 800px"></div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "set/creditHoursStatistics",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      userName: "",
      idCard: "",
      projectName: "",
      dialogCert: false,
      userCompanyList:[],
      unitCompId:'',
    };
  },
  created() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.idCard) {
        params.idcard = this.idCard;
      }
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.unitCompId) {
        params.compNameId = this.unitCompId;
      }
      this.doFetch({
        url: "/gov/project/queryCourseCertJGList",
        params,
        pageNum
      });
    },
    lookupTrainCert(projectId, userId) {
      let parma = {
        projectId: projectId,
        userId: userId
      };
      this.$post("/cert/downloadCert", parma).then(res => {
        if (res.status == 0) {
          this.dialogCert = true;
          this.$nextTick(() => {
            pdf.embed(res.data, "#pdf-cert");
          });
        } else {
          that.$message.warning({ message: res.message, duration: 1500 });
        }
      });
    },
      /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyFJList", { compName: query })
          .then(res => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    getTableHeight(opDom = true, page = true, tr = 40) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
      // this.pageSize = Math.ceil(this.tableHeight/tr)
    },
  }
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
